<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img src="img/user.png" class="img-avatar"/>
    </template>
    <template slot="dropdown">
      <!-- <b-dropdown-header tag="div" class="text-center">
        <strong>Admin</strong>
      </b-dropdown-header>-->
      <!-- <b-dropdown-item>
        <em class="fa fa-bell-o" /> Thông báo
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item>
        <em class="fa fa-envelope-o" /> Tin nhắn
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item>
        <em class="fa fa-tasks" /> Công việc
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <!-- <b-dropdown-item><em class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <!-- <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Settings</strong>
      </b-dropdown-header>
      <b-dropdown-item><em class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item><em class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><em class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><em class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <!-- <b-dropdown-divider /> -->
      <!-- <b-dropdown-item>
        <em class="fa fa-shield" /> Lock Survey
      </b-dropdown-item>-->
      <b-dropdown-item>
        <a class @click="login()">
          <em class="icon-login" />
          <!-- {{aLang.login}} -->
          Vào trang quản lý
        </a>
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";

import AuthService from "../service/AuthService";
import LanguageService from "../service/LanguageService";
export default {
  name: "DefaultHeaderDropdownAccntClient",
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      aLang: LanguageService.lang,
      itemsCount: 43
    };
  },
  methods: {
    login() {
      AuthService.me()
        .then(res => {
          this.$router.push({ name: "Admin-Home" });
        })
        .catch(error => {
          this.$router.push({ name: "Auth-Login" });
        });
    }
  }
};
</script>
