<template>
  <div class="app">
    <AppHeader fixed class="header-client">
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/home">
        <img class="navbar-brand-full" src="img/logo.png" width="auto" height="80%" alt="Logo" />
        <img class="navbar-brand-minimized" src="img/logo.png" width="auto" height="80%" alt="Logo" />
      </b-link>
      <!-- <SidebarToggler class="d-md-down-none" display="lg" /> -->
      <!-- <SidebarMinimizer class="d-md-down-none" display="lg" /> -->
      <b-navbar-nav class="d-md-down-none">
        <!-- <el-menu
          class="el-menu-header-client"
          mode="horizontal"
          background-color="#1e9f75"
          text-color="#fff"
          active-text-color="#ffd04b"
          :default-active="mData.defaultActive"
        >
          <el-menu-item index="1">
            <b-nav-item class="px-3 bold" router-link to="/home">Dashboard</b-nav-item>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <b-nav-item class="px-3 bold" exact>Tin tức</b-nav-item>
            </template>
            <div v-for="(item, index) in mData.listArticleCategory" :key="item.id">
              <el-menu-item v-bind:index="'2-'+(index + 1)" @click="fn_go(item.id)">{{item.name}}</el-menu-item>
            </div>
          </el-submenu>
          <el-menu-item index="3">
            <b-nav-item class="px-3 bold" router-link to="/aboutUs">Về chúng tôi</b-nav-item>
          </el-menu-item>
          <el-menu-item index="4">
            <b-nav-item class="px-3 bold" router-link to="/contactUs">Liên hệ</b-nav-item>
          </el-menu-item>
        </el-menu>-->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div class="line"></div>

        <!-- list icon -->
        <!-- <b-nav-item class="d-md-down-none">
          <i class="icon-bell icons"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>-->
        <!-- <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item>-->

        <!-- account -->
        <DefaultHeaderDropdownAccntClient />
      </b-navbar-nav>

      <!-- menu right -->
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!-- <AsideToggler class="d-lg-none" mobile /> -->
    </AppHeader>
    <div class="app-body">
      <!-- <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>-->
      <main class="main ml-0">
        <!-- <Breadcrumb :list="list"/> -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside />
      </AppAside>
    </div>

    <!--footer-->
    <!-- <TheFooter>      
      <div>
        <a href="https://coreui.io">CoreUI</a>
        <span class="ml-1">&copy; 2018 creativeLabs.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>
    </TheFooter>-->
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccntClient from "./DefaultHeaderDropdownAccntClient";

// import ApiService from "../service/ApiService";
// import ArticleCategoryApi from "../moduleApi/ArticleCategoryApi";

export default {
  name: "DefaultContainerClient",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccntClient,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      nav: nav.items,
      mData: {
        listArticleCategory: [],
        defaultActive: ""
      }
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  methods: {},
  watch: {},
  mounted() {
    // if (this.$router.currentRoute.name == "Client-Home") {
    //   this.mData.defaultActive = "1";
    // }
    // if (this.$router.currentRoute.name == "Client-article") {
    //   this.mData.defaultActive = "2";
    // }
    // if (this.$router.currentRoute.name == "Client-aboutUs") {
    //   this.mData.defaultActive = "3";
    // }
    // if (this.$router.currentRoute.name == "Client-contactUs") {
    //   this.mData.defaultActive = "4";
    // }
  }
};
</script>
<style>
</style>
